import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { LegLine, LegBone, TravelLeg } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { GridContainer, GridItem } from '@entur/grid';
import { colors } from '@entur/tokens';
import ex1 from './LegsExample1.png';
import ex2 from './LegsExample2.png';
import { travelleg } from './props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader">
  Travel leg benyttes til å illustrere reisestrekningen mellom to destinasjoner,
  og benytter transportfargen og Enturs mønster til det aktuelle
  transportmidlet.
    </PageHeader>
    <Playground __position={1} __code={'<div style={{ display: \'flex\', height: \'10rem\' }}>\n  <TravelLeg transport=\"bus\" direction=\"vertical\"></TravelLeg>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      LegLine,
      LegBone,
      TravelLeg,
      Playground,
      PageHeader,
      ImportStatement,
      GridContainer,
      GridItem,
      colors,
      ex1,
      ex2,
      travelleg,
      DefaultLayout,
      _frontmatter
    }} props={travelleg} mdxType="Playground">
  <div style={{
        display: 'flex',
        height: '10rem'
      }}>
    <TravelLeg transport="bus" direction="vertical" mdxType="TravelLeg"></TravelLeg>
  </div>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "travelleg"
    }}>{`TravelLeg`}</h3>
    <ImportStatement imports="TravelLeg" mdxType="ImportStatement" />
    <Props of={TravelLeg} mdxType="Props" />
    <p>{`Ved å bruke TravelLeg istedet for LegLine og/eller LegBone, kan man enkelt få riktig farge ved å velge transportmodalitet.
Disse tar også hensyn til om man er i en Kontrast-seksjon eller ikke.`}</p>
    <h3 {...{
      "id": "legbone"
    }}>{`LegBone`}</h3>
    <ImportStatement imports="LegBone" mdxType="ImportStatement" />
    <Props of={LegBone} mdxType="Props" />
    <h3 {...{
      "id": "legline"
    }}>{`LegLine`}</h3>
    <ImportStatement imports="LegLine" mdxType="ImportStatement" />
    <Props of={LegLine} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Travel leg benyttes oftest vertikalt, men kan også benyttes horisontalt i grensesnitt der dette passer bedre.`}</p>
    <h3 {...{
      "id": "eksempel"
    }}>{`Eksempel`}</h3>
    <p>{`Her er et eksempel på hvordan man har tatt i bruk travel legs på reisedetaljer siden i reiseplanleggeren til Entur.`}</p>
    <GridContainer spacing="large" mdxType="GridContainer">
  <GridItem snall={12} medium={6} large={4} mdxType="GridItem">
    <img src={ex1} style={{
          border: `2px solid  ${colors.greys.grey80}`
        }} alt="Skjermbilde av Entur-appen som illustrer bruk av travel leg" />
  </GridItem>
  <GridItem small={12} medium={6} large={4} mdxType="GridItem">
    <img src={ex2} style={{
          border: `2px solid  ${colors.greys.grey80}`
        }} alt="Skjermbilde av Entur-appen som illustrer bruk av travel leg" />
  </GridItem>
    </GridContainer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      